import type { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import type { WidgetProps } from 'yoshi-flow-editor-runtime';
import { INavigateToSectionOptions } from '@wix/native-components-infra/dist/src/types/types';

export enum TabState {
  REGULAR = 'regular',
  HIGHLIGHTED = 'highlighted',
}

export interface TextData {
  ['button-text']?: string;
  ['disclaimer-text']?: string;
  ['free-trial-button-text']?: string;
  ['page-title-text']?: string;
  ['ribbon-text']?: string;
}

export enum PopupEnum {
  checkoutPreview = 'CHECKOUT_PREVIEW',
}

export enum MessageCode {
  PURCHASE_LIMIT_ERROR = 1,
  UNKNOWN_ERROR = 2,
  CHECKOUT_DEMO = 3,
  START_DATE_CHANGED = 4,
  START_DATE_UPDATE_FAILED = 5,
  CHECKOUT_PLAN_NOT_FOUND = 6,
  CHECKOUT_PAYLOAD_DECODE_FAILED = 7,
}

interface VerticalStatusContent {
  titleText: string;
  contentText?: string;
  buttonText: string;
}

interface BiOptions {
  referralInfo: string;
  referralId?: string;
}

interface NavigateToSectionProps {
  appDefinitionId: string;
  sectionId: string;
  queryParams?: Object;
}

export interface IntegrationData {
  planIds?: string[];
  navigateToSectionProps?: NavigateToSectionProps;
  navigateToPageProps?: string;
  verticalStatusContent?: VerticalStatusContent;
  biOptions?: BiOptions;
  restrictedPageId?: string;
  minStartDate?: string;
  maxStartDate?: string;
}

export interface CheckoutData {
  integrationData?: IntegrationData;
  planId: string;
  orderId?: string;
}

export interface StatusData {}

export type SubPage =
  | { name: 'list'; message?: MessageCode }
  | { name: 'checkout'; plan: PublicPlan; checkoutData: CheckoutData }
  | { name: 'status'; data: StatusData };

interface _PackagePickerProps {
  plans: PublicPlan[];
  tabState: TabState;
  isMobile?: boolean;
  popup: PopupEnum | null;
  subPage: SubPage;
  orderId?: string;

  navigateToCheckout(plan: PublicPlan): void;
  navigateToStatus(): void;
  navigateToPlanList(): void;
  navigateBack(): void;
  hidePopup(): void;
}

export type PackagePickerProps = WidgetProps<_PackagePickerProps>;

export interface IEvents {
  tabState: TabState;
}
